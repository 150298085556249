import { BASE_URL } from "../config";
import axios from "axios";

const PATH = {
  clientImages: "unp/business-website/client",
  sendLead: "unp/business-website/send-lead",
  subNewsletter: "unp/business-website/newsletter-sub",
  verifyToken: "unp/business-website/verify-captcha",
  inNews: "unp/business-website/in-news",
};

const ClientImages = async () => {
  const res = await axios.get(`${BASE_URL}/${PATH.clientImages}/`);
  return res;
};

const VerifyToken = async (token) => {
  const res = await axios.post(
    `${BASE_URL}/${PATH.verifyToken}/`,
    {},
    {
      params: { token },
    }
  );
  return res;
};

const SendLead = async (payload, token) => {
  const res = await axios.post(`${BASE_URL}/${PATH.sendLead}/`, payload, {
    params: { token },
  });
  return res;
};

const SubNewsletter = async (payload, token) => {
  const res = await axios.post(`${BASE_URL}/${PATH.subNewsletter}/`, payload, {
    params: { token },
  });
  return res;
};

const GetInNews = async () => {
  const res = await axios.get(`${BASE_URL}/${PATH.inNews}/`);
  return res;
};

export const HomeService = {
  ClientImages,
  SendLead,
  SubNewsletter,
  VerifyToken,
  GetInNews
};
