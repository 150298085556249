import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import classes from "./index.module.scss";
import Reveal from "../../Utils/Reveal";
import { getLinkPreview } from "link-preview-js";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HomeService } from "../../services/homeService";

const InNews = () => {
  const [previews, setPreviews] = useState([]);

  useEffect(() => {
    const fetchPreviews = async () => {
      let data = [];
      try {
        const res = await HomeService.GetInNews();
        if (Array.isArray(res.data?.data)) {
          data = res.data.data;
        }
      } catch (error) {
        console.error("Error fetching inNews", error);
      }

      const results = await Promise.all(
        data.map(async (item) => {
          try {
            const response = await getLinkPreview(item.url);
            return {
              ...response,
              link: item.link,
              text: item.title,
            };
          } catch (error) {
            console.error(`Error fetching preview for ${item.link}`, error);
            return null;
          }
        })
      );
      setPreviews(results.filter((item) => item !== null));
    };

    fetchPreviews();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      {previews.length > 0 ? (
        <>
          <Reveal>
            <div className={classes.ContainerHeader}>In News</div>
            <hr className={classes.ContainerHeaderBorder} />
          </Reveal>
          <div className={classes.CarouselParent}>
            <Reveal>
              <div className={classes.CarouselContainer}>
                <Slider {...settings}>
                  {previews.map((preview, index) => (
                    <div>
                      <a className={classes.Card} key={index} href="">
                        <img
                          src={preview.images?.[0]}
                          alt={preview.title}
                          className={classes.CardImage}
                        />

                        <div className={classes.CardText}>{preview.text}</div>
                      </a>
                    </div>
                  ))}
                </Slider>
              </div>
            </Reveal>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default InNews;
